<template>
  <div class="navbar-floating">
    <the-navbar-vertical-public theTitle="Test technique"/>
    <div class="min-h-screen flex tw-w-full">
      <div class="main-content mx-auto self-center">
        <div>
          <div class="full-page-bg-color">
            <div class="vx-row justify-center">
              <div class="vx-col left my-6">
                <div>
                  <HBtn :loading="loading" @click="startTest">Lancer le test</HBtn>
                </div>
                <div v-if="initialized && !loading">
                  <div>
                    Résultats de recherche : {{ resultsDefaultHosts ? 'OK' : 'KO' }} ({{ resultsDefaultHosts }} résultats trouvés)
                  </div>
                  <div v-if="errorDefaultHosts">
                    Erreur lors de la connexion à Algolia : <pre>{{ errorDefaultHosts }}</pre>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
        <footer class="the-footer flex-wrap justify-between">
          <p>
            <span>COPYRIGHT &copy;</span>
            <span>{{ new Date().getFullYear() }} </span>
            <a href="https://www.happytal.com" rel="nofollow" target="_blank">happytal</a>
            <span class="tw-hidden sm:tw-inline-block">, Tous droits réservés</span>
          </p>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import algoliasearch from 'algoliasearch'

import {
  HBtn,
} from '@happytal/bo-ui-library'

import TheNavbarVerticalPublic from '@/components/navbar/TheNavbarVerticalPublic.vue'

import appsettings from '@/appsettings'

const vueAppTestMode = appsettings.VUE_APP_TEST_MODE
const vueAppAlgoliaIndexName = appsettings.VUE_APP_ALGOLIA_INDEX_NAME
const vueAppAlgoliaApplicationId = appsettings.VUE_APP_ALGOLIA_APPLICATION_ID
const vueAppAlgoliaApiKey = appsettings.VUE_APP_ALGOLIA_API_KEY

export default {
  components: {
    HBtn,
    TheNavbarVerticalPublic,
  },
  data() {
    return {
      initialized: false,
      loading: false,
      resultsDefaultHosts: 0,
      errorDefaultHosts: undefined,
    }
  },
  computed: {
  },
  methods: {
    async getSearchResultCount() {
      const searchClient = algoliasearch(vueAppAlgoliaApplicationId, vueAppAlgoliaApiKey)
      const searchResults = await searchClient.search([
        {
          indexName: vueAppAlgoliaIndexName
        }
      ])
      return searchResults.results[0]?.hits?.length
    },
    async startTest() {
      try {
        this.initialized = true
        this.loading = true
        this.resultsDefaultHosts = 0
        this.errorDefaultHosts = undefined
        this.resultsDefaultHosts = await this.getSearchResultCount()
      } catch (err) {
        this.errorDefaultHosts = err
      } finally {
        this.loading = false
      }
    }
  },
}
</script>
<style lang="scss">
@import '~@happytal/bo-ui-library/src/styles/variables';
$default-col-width: 620px;

.main-content {
  width: 1300px;
  max-width: 100%;
  padding: 120px 3.4rem 0;

  @media (max-width: map-get($breakpoints, sm)) {
    padding: 120px 2.4rem 0;
  }
}

.vx-card {
  background: none;
  border-radius: 0;
  box-shadow: none;
  transition: none;
  background: #F8F8F8;
}

.vx-col.left {
  background: #F8F8F8;
  flex: 1;
  width: $default-col-width;
  max-width: 100%;

  @media (max-width: map-get($breakpoints, md)) {
    flex: 0 0 $default-col-width;
  }
}

.vx-col.right {
  background: #fff;
  border-radius: .5rem;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
  width: $default-col-width;
  max-width: 100%;

  .actions {
    display: flex;
    justify-content: flex-end;
  }
}

.m-t-30 {
  margin-top: 40px !important;
}

.vs-alert span, .vs-alert h4 {
  font-size: 13px;
}

.vs-alert {
  padding: 8px 10px;
}

.vs-alert span {
  font-size: 13px;
}

.vx-row {
  background: #F8F8F8;
}

.vs-alert--title {
  font-size: .9rem;
  font-weight: 700;
  padding: 0px;
}

.logo-login img {
  max-width: 263px !important;
  width: 263px !important;
  margin: 65px auto;
}

footer {
  margin-top: 40px;

  p {
    margin: 0 auto;
    display: block;
    text-align: center;
  }

}
</style>
